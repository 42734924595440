.container {
  display: flex;

  &.verify-container {
    .sticky-header {
      height: 30px;
      display: flex;
      flex: 0 0;
      flex-basis: 40px;
      align-items: center;
      justify-content: flex-end;
      background-color: #FFF;
      padding: 10px 20px;
      a {
        color: #000;
        padding-left: 20px;
      }
    }

    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    font-weight: bold;
    color: #fff;
    font-size: 1rem;
    position: absolute;
    inset: 0;

    .content {
      // background-color: #202125;
      padding: 10px 20px;
    }

    a {
      color: yellow;
      text-decoration: underline;
    }

    p {
      padding-left: 20px;

      &.no-padding {
        padding-left: 0px;
      }
    }

    ol.li-alpha {
      list-style: none;
      counter-reset: my-counter;
      padding-left: 18px;

      li {
        margin-block-end: 1rem;
        counter-increment: my-counter;
        display: flex;

        &::before {
          content: "(" counter(my-counter, lower-alpha) ")";
          font-weight: bold;
          padding-right: 10px;
        }

        p {
          padding-left: 10px;
        }
      }
    }

    ol.li-roman {
      list-style: none;
      counter-reset: my-counter-b;
      padding-left: 30px;

      li {
        margin-block-end: 1rem;
        counter-increment: my-counter-b;
        display: flex;

        &::before {
          content: "(" counter(my-counter-b, lower-roman) ")";
          font-weight: bold;
          padding-right: 10px;
        }

        p {
          padding-left: 10px;
        }
      }
    }
  }
  iframe{
    width:100%;
    height: 100%;
    margin:0px;
    padding:0px;
    border:none;
    overflow: hidden;
}
h2{
  color:#000
}
}

.accept-button {
  margin: 10px 0 20px;

  button {
    background-color: #2a2b2f;
    padding: 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 1rem;
    height: 30px;
    padding-left: 30px;
    width: 250px;
    color: #fff;
    opacity: 1;

    &:after {
      content: "";
      border: solid #a2c9ea;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
      position: absolute;
      right: 20px;
      opacity: 1;
    }
    &:hover {
      color: #000;
    }

    &:disabled {
      opacity: 0.5;
      color: #fff;

      &:after {
        opacity: 0.5;
      }
    }
  }
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.agree {
  margin-top: 16px;
  border-top: 1px solid #000;
  padding-top: 16px;
  color: #000;

  input {
  }
}

.container {
  display: flex;

  a,
  a:visited {
    text-decoration: none;
    color: #fff;
  }

  a:hover {
    text-decoration: underline;
  }

  &.receipt-container {
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    min-width: 400px;
    margin: 0 auto;
    display: flex;
    font-weight: bold;
    color: #fff;
    font-size: 1rem;

    .receipt-header {
      background-color: #2a2b2f;
      display: flex;
      padding: 20px;
      align-items: center;

      .logo {
        width: 70px;
        height: 12px;
        content: "";
        background-image: url("/logo-logo-hori-light.svg");
        background-size: 70px;
        background-repeat: no-repeat;
        background-position: left center;
      }

      .text {
        font-size: 1.1rem;
        display: flex;
        align-items: center;
        text-align: right;
        flex: 1;
        justify-content: right;
      }
    }

    dl.route-info {
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 5px;
      margin-bottom: 0;

      dd,
      dt {
        display: flex;
        align-items: center;
        font-size: 1rem;
        margin-left: 18px;
        color: #fff;
      }

      dd {
        &:before {
          content: "\25CF";
          display: inline-block;
          width: 1.5rem;
          margin-left: -1.5rem;
          font-size: 2rem;
          padding-bottom: 3px;
          line-height: 0;
        }

        &.start:before {
          color: #69df7a;
        }

        &.end:before {
          color: #ff0000;
        }

        &.stop:before {
          content: "";
        }
      }

      dt {
        margin-bottom: 0;
      }
    }

    .map {
      dl.route-info {
        flex-direction: column;
        align-items: flex-start;

        .left {
          margin-bottom: 15px;
        }

        dt {
          font-size: 1.3rem;
        }
      }
    }

    .route {
      dl.route-info {
        margin: 0;

        dt {
          font-size: 1rem;
        }
      }
    }

    .blocks {
      display: flex;
      justify-content: space-between;
      flex: 0;
      gap: 10px;

      &.flex-column {
        flex-direction: column;

        .column {
          justify-content: flex-start;
        }
      }

      .column {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
      }
      .info-block-horizontal{
        display: flex;
        flex-direction: row;
        width: 100%; /* Adjust width of dt as needed */
        color:#8f9599;
        font-size: 1.1rem;
        dt{
          width: 50%; /* Adjust width of dt as needed */
          text-align: left;
        }
        dd{
          width: 100%; /* Adjust width of dt as needed */
          text-align: right;
        }
      }
      .total{
        color: #fff;
      }
      .info-block {
        display: flex;
        flex-direction: column;
        flex: 1;
        color: #8f9599;
        justify-content: space-between;
        padding-right: 10px;

        dt,
        dd {
          align-items: center;
          display: flex;
        }

        dt {
          height: 48px;
          //font-size: 1.1rem;
        }

        dd {
          margin-left: 0px;
          position: relative;
          flex: 1;
          justify-content: start;
          font-size: 1.5rem;
          line-height: 1.1;
          align-items: self-start;
        }

        &.car-plate {
          background-image: url("/icon-taxi-toyota.svg");
          background-size: 97px;
          background-repeat: no-repeat;
          background-position-x: left;
          background-position-y: center;

          dd {
            align-items: center;

            span {
              background-color: #ffbc25;
              color: #000;
              border-radius: 4px;
              margin-left: 34px;
              height: 100%;
              padding: 0 15px;
              display: flex;
              align-items: center;
              font-size: 1.2rem;
              max-height: 32px;
            }
          }
        }

        &.credit-card {
          flex: 1;
          font-size: 1.3rem;
          color: #fff;
          dt{
            img{
              padding-right: 5px;
            }
          }
        }

        &.total {
          font-size: 2.2rem;
          color: #fff;
          flex: 1;
        }
      }
    }

    .receipt-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: #202125;
      padding: 20px;

      .info {
        flex: 0;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #8f9599;
        margin-bottom: 20px;

        .price {
          flex: 0;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: center;
          line-height: 1.2;
          padding-bottom: 20px;

          .chip{
            font-size: 0.9rem;
            background-color: #41C468;
            padding: 0px 8px;
            border-radius: 3px;
            margin: 0px 5px;
            vertical-align: middle;
          }

          .title {
            font-size: 1.5rem;
          }

          .price-amount {
            font-size: 2.2rem;
          }
        }
      }

      .map {
        flex: 0;
        display: flex;
        margin-bottom: 20px;
        flex-direction: row;

        .title {
          font-size: 1.5rem;
        }

        .column {
          flex: 1;

          &.map-container {
            padding: 0;
            height: 200px;
            border-radius: 14px;
            overflow: hidden;
          }
        }
      }

      .point {
        padding: 3px 6px;
        display: inline-flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        transform: translate(-50%, -50%);

        &start {
          @extend .point;
          background: #69df7a;
        }

        &end {
          @extend .point;
          background: #ff0000;
        }
      }

      .route {
        flex: 2.5;
        border: 1px solid #8f9599;
        border-radius: 7px;
        overflow: scroll;
        font-size: 0.8rem;
      }

      .payment {
        flex: 0;
        margin-bottom: 20px;
        margin-top: 20px;
        border-top: 1px solid #8f9599;
        border-bottom: 1px solid #8f9599;
        padding-top: 20px;
        padding-bottom: 20px;

        .title {
          font-size: 1.5rem;
          margin-bottom: 20px;
        }

        .payment-logo {
          margin: 0;
          padding: 0;
        }
      }

      .footer {
        flex: 0;
        font-size: 1rem;
        display: flex;
        flex-wrap: wrap;

        .terms {
          width: 80%;
          margin: 0 10%;
          margin-bottom: 20px;
          display: flex;
          justify-content: center;

          .button {
            background-color: #2a2b2f;
            padding: 10px;
            border-radius: 30px;
            cursor: pointer;
            font-size: 1rem;
            height: 30px;
            display: flex;
            align-items: center;
            padding-left: 30px;
            width: 250px;

            &:after {
              content: "";
              border: solid #a2c9ea;
              border-width: 0 3px 3px 0;
              display: inline-block;
              padding: 3px;
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              position: absolute;
              right: 20px;
            }
          }
        }

        .left {
          justify-content: start;
          flex: 1;
        }

        .right {
          text-align: right;
          flex: 2;
        }

        .center {
          justify-content: center;
          align-items: center;
          flex: 1;
          text-align: center;
        }

        .rights {
          color: #fff;
          font-weight: 400;
        }
      }

      table {
        tr {
          border: none;

          &:last-child {
            td {
              padding-bottom: 15px;
            }
          }
        }

        th,
        td {
          padding: 7px 10px;
          line-height: 1;
          color: #8f9599;
          border: none;
          font-weight: bold;
          font-size: 1rem;

          &:first-child {
            padding-left: 20px;
          }

          &:last-child {
            padding-right: 20px;
          }
        }

        th {
          background-color: #2a2b2f;
          padding: 15px 20px;
        }

        tr.odd-color {
          background-color: #303135;
        }
      }
    }

    @media only screen and (max-width: 460px) {
      .receipt-body .map {
        flex-direction: column;

        .column {
          flex: 0;

          dl.route-info {
            flex-direction: row;
            gap: 40px;

            .left,
            .right {
              flex: 1;
            }
          }
        }

        .map-container {
          flex: 0;
          flex-basis: 200px;
        }
      }
    }
  }
}

@media only print {
  @page {
    size: A4;
    margin: 0;
  }
  .container {
    color: #000;
    &.receipt-container {
      border: 1px solid #999;
      .receipt-header {
        background-color: #fff;
        border-bottom: 1px solid #999;
        color: #000;
        .logo {
          print-color-adjust: economy;
        }
      }

      .receipt-body {
        background-color: #fff;

        .info {
          .title,
          .price-amount {
            color: #000;
          }
        }

        & * {
          color: #000;
        }
        .route,
        .payment {
          border: none;
        }
        .route {
          th,
          td {
            background-color: #fff;
            border-bottom: 1px solid #eee;
            border-collapse: collapse;
          }
        }
        .payment {
          border-top: 1px solid #999;
          border-bottom: 1px solid #999;
        }
        .footer {
          .terms {
            display: none;
          }
          color: #000;
          .rights {
            color: #000;
          }
        }
      }
      dl.route-info {
        dd,
        dt {
          color: #000;
        }
      }
    }
  }
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

.container {
  width: 100%;
}

main {
  height: 100%;
  width: 100%;
}
